var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "showtimes-row" }, [
      _c("h2", [_vm._v("Showtimes")]),
      _vm._v(" "),
      _vm.selectedDate
        ? _c(
            "div",
            { staticClass: "showtimes-date" },
            [
              _c("h3", {
                domProps: {
                  innerHTML: _vm._s(_vm.formatShowdate(_vm.selectedDate)),
                },
              }),
              _vm._v(" "),
              _vm._l(_vm.showtimesToDisplay, function (showtime) {
                return _c("p", { key: showtime.linkId }, [
                  _vm.pcId
                    ? _c("a", {
                        attrs: {
                          href:
                            "https://ticket.schulmantheatres.com/#/screen/" +
                            showtime.pcShowId +
                            "?cinemaId=" +
                            _vm.pcId,
                          target: "_blank",
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.formatShowtime(showtime)),
                        },
                      })
                    : _c("a", {
                        staticClass: "show-date-selector",
                        attrs: {
                          href:
                            _vm.rtsUrl +
                            ":2235/T.ASP?WCI=BT&Page=PickTickets&SHOWID=" +
                            showtime.linkId,
                          target: "_blank",
                        },
                        domProps: {
                          innerHTML: _vm._s(_vm.formatShowtime(showtime)),
                        },
                      }),
                ])
              }),
              _vm._v(" "),
              _vm.showPrevArrow
                ? _c(
                    "a",
                    {
                      staticClass: "prev",
                      on: {
                        click: function ($event) {
                          return _vm.prevDate()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/img/chevron-left.png", alt: "Prev" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showNextArrow
                ? _c(
                    "a",
                    {
                      staticClass: "next",
                      on: {
                        click: function ($event) {
                          return _vm.nextDate()
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: "/img/chevron-right.png", alt: "Next" },
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _c("div", { staticClass: "showtimes-date" }, [
            _c("h3", [_vm._v("No showtimes to display.")]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }